<template >
     <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.services"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.nomi')"
                            prop="name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.name"
                                v-model="form.name"
                                :placeholder="$t('message.nomi')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.services')"
                            class="label_mini"
                        >
                        <select-services-of-setting v-model="form.parent_id" :id="form.parent_id" size="medium"></select-services-of-setting>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.code')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.code"
                                v-model="form.code"
                                :placeholder="$t('message.code')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.lifetime')"
                        >
                            <el-date-picker
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"                                     
                                type="date"
                                :placeholder="$t('message.lifetime')"
                                v-model="form.lifetime"
                                style="width: 100%"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-checkbox style="width: 200px" v-model="form.is_parent" :label="$t('message.parent')" border size="large"></el-checkbox>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import SelectServicesOfSetting from '@/components/filters/inventory/select-servicesOfSetting';
export default {
    mixins: [form, drawer, show],
    components: {SelectServicesOfSetting},
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "servicesOfSetting/rules",
            model: "servicesOfSetting/model",
            columns: "servicesOfSetting/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "servicesOfSetting/update",
            show: "servicesOfSetting/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
